import React from "react";

import { graphql, PageProps } from "gatsby";

import { Container } from "../components/container";
import { LayoutPage } from "../components/layout-page";
import { Seo } from "../components/seo";
import { ServiceGrid } from "../components/service-grid";
import { Title } from "../components/title";
import styles from "../styles/shopify.css";

export default function ShopifyPage({ data: { page } }: PageProps<any>) {
  return (
    <>
      <Seo
        description={page.seo.description}
        image={page.seo.image?.asset.gatsbyImageData.images.fallback.src}
        title={page.seo.title}
      />

      <LayoutPage
        heroButtonText={page.hero.button}
        heroDescription={page.hero.description}
        heroImage={page.hero.image.asset.gatsbyImageData}
        heroImageAlt="Shopify"
        heroTitle={page.hero._rawTitle}
      >
        <Container className={styles.container} variant="gradient">
          <Title
            pretitle={page.sectionServicesHeader.pretitle}
            subtitle={page.sectionServicesHeader.subtitle}
            text={page.sectionServicesHeader.title}
          />
          <ServiceGrid items={page.sectionServicesItems} />
        </Container>
      </LayoutPage>
    </>
  );
}

export const query = graphql`
  query {
    page: sanityPageShopify {
      seo {
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, width: 1200, height: 630)
          }
        }
      }
      hero {
        _rawTitle
        description
        button
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 1050)
          }
        }
      }
      sectionServicesHeader {
        pretitle
        title
        subtitle
      }
      sectionServicesItems {
        _key
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 400)
          }
        }
      }
    }
  }
`;
